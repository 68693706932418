export function SlideshowBlockIcon(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      className={props.className ?? 'w-3.5 h-3.5 fill-current'}
    >
      <rect width='24' height='24' rx='6' fill='#3988FF' />
      <rect
        x='8.5'
        y='9.5'
        width='12'
        height='9'
        rx='1.5'
        fill='#3988FF'
        stroke='white'
      />
      <rect
        x='6.5'
        y='7.5'
        width='12'
        height='9'
        rx='1.5'
        fill='#3988FF'
        stroke='white'
      />
      <rect x='4' y='5' width='13' height='10' rx='2' fill='white' />
    </svg>
  );
}
