export function JeopardyBlockIcon(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      className={props.className ?? 'w-3.5 h-3.5 fill-current'}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7 1V7.84096C7 9.11426 6.80537 9.95909 6.41611 10.3755C6.02685 10.7918 5.2349 11 4.04027 11C2.76957 11 1.94407 10.7991 1.56376 10.3972C1.18792 9.9954 1 9.11668 1 7.76107L1.02013 7.0276H2.67785C2.7047 7.39555 2.71812 7.67151 2.71812 7.85548C2.71812 8.02977 2.72483 8.24038 2.73826 8.48729C2.7651 9.08763 3.20582 9.3878 4.0604 9.3878C4.57047 9.3878 4.89709 9.28129 5.04027 9.06826C5.18792 8.8504 5.26174 8.36383 5.26174 7.60857V1H7Z'
        fill='currentColor'
      />
      <path
        d='M9.68741 9.05714C9.68741 9.4009 9.97256 9.67957 10.3243 9.67957C10.676 9.67957 10.9612 9.4009 10.9612 9.05714V9.01699C10.9612 8.73014 11.2328 8.43382 11.8563 8.06196C12.5601 7.65291 13 7.09512 13 6.24355C13 4.98665 11.9863 4 10.5251 4C9.19194 4 8.30109 4.63281 8.01797 5.57506C7.92814 5.87403 8.18306 6.33279 8.60495 6.33279C9.02684 6.33279 9.35868 6.18255 9.55079 5.82644C9.74289 5.47033 10.138 5.29901 10.5251 5.29901C11.1104 5.29901 11.5809 5.67831 11.5809 6.26214C11.5809 6.78275 11.2251 7.13974 10.7623 7.42236C10.0852 7.83141 9.68741 8.44334 9.68741 8.86814V9.05714ZM10.3568 12C10.8082 12 11.1945 11.6356 11.1984 11.1819C11.1945 10.7357 10.8082 10.3712 10.3568 10.3712C9.89015 10.3712 9.51146 10.7357 9.51528 11.1819C9.51146 11.6356 9.89015 12 10.3568 12Z'
        fill='currentColor'
      />
    </svg>
  );
}
